var PlatformDetector = (function(){

    'use strict';

    var $mobileUserAgentRegex,
        $userAgent,
        $iosRedirectMode,
        $iosRegex,
        $iosSafariRegex,
        $androidRegex,
        $androidChromeRegex;

    var PlatformDetector = function(userAgent, iosRedirectMode, iosRegex, iosSafariRegex, androidRegex, androidChromeRegex){
        if(!userAgent){
            userAgent = navigator.userAgent;
        }
        $userAgent = userAgent;

        $iosRedirectMode = iosRedirectMode;
        $iosRegex = new RegExp(iosRegex);
        $iosSafariRegex = new RegExp(iosSafariRegex);

        $androidRegex = new RegExp(androidRegex);
        $androidChromeRegex = new RegExp(androidChromeRegex);
    };

    PlatformDetector.prototype = {

        constructor: PlatformDetector,

        isIos: function(){
            return $iosRegex.test($userAgent);
        },

        isAndroid: function(){
            return $androidRegex.test($userAgent);
        },

        isMobile: function(){
            return this.isIos() || this.isAndroid();
        },

        isChromeOnAndroid: function(){
            return this.isAndroid() && $androidChromeRegex.test($userAgent);
        },

        isInIframe: function() {
            try {
                return window.self !== window.top;
            } catch (e) {
                // access to window.top may throw if not same origin
                return true;
            }
        },

        getRedirectForPlatform: function(redirectUrl, currentUrl){
            if (this.isIos()) {
                // Use explicitly given redirectUrl if given
                if(redirectUrl){
                    return encodeURIComponent(redirectUrl);
                }

                // Otherwise act according to configured redirect mode
                currentUrl = currentUrl ? currentUrl : document.location.href;
                var redirectValue = this.createRedirectForIos(currentUrl);
                return encodeURIComponent(redirectValue);
            }
            return 'null';
        },

        createRedirectForIos: function (currentUrl) {
            switch ($iosRedirectMode) {
                case 'DETECT_BROWSER':
                    return this.createRedirectUrlBasedOnBrowser(currentUrl);
                case 'NO_REDIRECT':
                    return 'null';
                case 'DEFAULT_BROWSER':
                default:
                    return currentUrl;
            }
        },

        createRedirectUrlBasedOnBrowser: function (currentUrl) {
            if ($iosSafariRegex.test($userAgent)) {
                return currentUrl;
            } else {
                return 'null';
            }
        }

    };

    return PlatformDetector;

})();