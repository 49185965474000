var UserMessageMapper = (function (){

    'use strict';

    var dictionary;

    var UserMessageMapper = function () {

        dictionary = {

            // Signicat errors
            "urn:signicat:error:sbid2014:SIG100": "technicalError",
            "urn:signicat:error:sbid2014:SIG200": "technicalError",
            "urn:signicat:error:sbid2014:SIG300": "technicalError",
            "urn:signicat:error:sbid2014:SIG500": "invalidIdNumber",

            // Error codes (response.error.code)
            "INVALID_PARAMETERS": "technicalError",
            "ACCESS_DENIED_RP": "technicalError",
            "ALREADY_IN_PROGRESS": "RFA3",
            "CANCELLED": "RFA3",
            "INTERNAL_ERROR": "RFA5",
            "CLIENT_ERROR": "RFA5",
            "CLIENT_ERR": "RFA12",
            "RETRY": "RFA5",
            "USER_CANCEL": "RFA6",
            "EXPIRED_TRANSACTION": "RFA8",
            "CERTIFICATE_ERR": "RFA16",
            "START_FAILED": "RFA17A",
            "START_FAILED_QR": "RFA17B",

            // Collect response status codes (response.progressStatus)
            "OUTSTANDING_TRANSACTION": "RFA13", //if autostart, else RFA1
            "USER_SIGN": "RFA9",
            "COMPLETE": "pleaseWait",
            "NO_CLIENT": "RFA1",
            // STARTED is a well defined collect response, but it's kind of an error really
            // It means BISP/BISApp was started, but a BankID of the required kind is missing
            "STARTED": "RFA15",

            // General messages
            "PLEASE_WAIT": "pleaseWait",
            "TECHNICAL_ERROR": "technicalError",
            "TRY_AUTO_LAUNCH": "tryAutoLaunch",
            "MANUAL_LAUNCH": "manualLaunch",
            "MANUAL_LAUNCH_WAITING": "RFA13",
            "MANUAL_LAUNCH_ON_ANOTHER_DEVICE": "manualLaunch-anotherDevice",
            "SCAN_QR_CODE": "scanQrCode",
            "SEARCHING": "searchingForBankid"
        };
    };

    UserMessageMapper.prototype = {

        constructor: UserMessageMapper,

        get: function(key) {
            return 'sbid2014-' + dictionary[key];
        }
    };

    return UserMessageMapper;
})();
