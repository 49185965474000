var signicat = signicat || { };

signicat.addEvent = function(element, event, fn) {
    if (element.addEventListener) {
        element.addEventListener(event, fn, false);
    }
    else if (element.attachEvent) {
        element.attachEvent('on' + event, fn);
    }
};

signicat.jsonParse = function(json){

    try{
        return JSON.parse(json);
    }
    catch(e){
        return {
            error: {
                code: "TECHNICAL_ERROR",
                message: e.message
            }
        }
    }
};

// See the java class "PersonalNumberValidator"
signicat.verifyIdNumber = function(personalNumber) {
  /*
   * Reads a personal number on any (legal) form and guesses what century the person the number
   * belongs to is born in. The algorithm assumes the person is not over 100 years of age unless
   * the '+'-based format is used.
   * @param personalNumber personal number raw string
   * @return two-digit century number (e.g 19)
   */
  var guessCentury = function(personalNumber) {
      // Format is YYYYMMDDXXXX or YYYYMMDD-XXXX. Century is given.
      if (personalNumber.length === 12 || personalNumber.length === 13) {
          return parseInt(personalNumber.substring(0, 2));
      }

      var over100Years = personalNumber.charAt(6) === '+';
      var twoDigitBirthYear = parseInt(personalNumber.substring(0, 2));
      var curYear = new Date().getFullYear();
      var curCentury = Math.floor(curYear / 100);
      var lastCentury = curCentury - 1;
      var curCenturyBirthYear = curCentury * 100 + twoDigitBirthYear;
      var lastCenturyBirthYear = lastCentury * 100 + twoDigitBirthYear;

      // Format is YYMMDDXXXX or YYMMDD-XXXX
      if (personalNumber.length === 10 || (personalNumber.length === 11 && !over100Years)) {
          // Is the person born in the current century?
          if (curCenturyBirthYear <= curYear) {
              return curCentury;
          }
          return lastCentury;
      }

      // Format is YYMMDD+XXXX
      if (personalNumber.length === 11 && over100Years) {
          // A person over 100 cannot be born this century, but may be born the last or the one before that.
          if (lastCenturyBirthYear + 100 <= curYear) {
              return lastCentury;
          }
          return lastCentury - 1;
      }
      // Failed.
      return -1;
  }

  /**
   * Convert a 12-digit personal number to a 10-digit one.
   *
   * @param personalNumber personal number on any valid format
   * @return a 10-digit personal number
   */
  var toTenDigitFormat = function(personalNumber) {
      if (personalNumber.length === 10) {
          return personalNumber;
      }
      var withoutSigns = personalNumber.replace("-", "").replace("+", "");
      if (personalNumber.length === 11) {
          if (withoutSigns.length !== 10) {
            return "";
          }
          return withoutSigns;
      }
      if (personalNumber.length === 12) {
          return personalNumber.substring(2);
      }
      if (personalNumber.length === 13) {
          if (withoutSigns.length !== 12) {
            return "";
          }
          return withoutSigns.substring(2);
      }
      return "";
  }

  var validateChecksum = function(number) {
      var accumulator = 0;
      for (var i = 0; i<number.length-1; i++) {
          var curDigit = parseInt(number.charAt(i));
          if (isNaN(curDigit)) {
            return false;
          }
          var mult = 2 - i % 2;
          var toAdd = curDigit * mult;
          var oneDigitAdd = Math.floor(toAdd / 10) + toAdd % 10;
          accumulator += oneDigitAdd;
      }
      var checksum = (10 - accumulator % 10) % 10;
      return checksum  === parseInt(number.charAt(number.length - 1));
  }


  if (personalNumber.length < 10) {
      return false;
  }
  //  If there is a hyphen or a plus sign, it has to be in the right spot (character index 6).
  var minusOnly = personalNumber.replace('+', '-');
  if (minusOnly.indexOf("-") !== -1) {
      var split = minusOnly.split("-");
      if ((personalNumber.length === 11 && split[0].length !== 6)
              || (personalNumber.length === 13 && split[0].length !== 8)) {
        return false;
      }
  }

  var century = guessCentury(personalNumber);
  if (century === -1) {
    return false;
  }
  var tenDigitFormat = toTenDigitFormat(personalNumber);
  if (tenDigitFormat === "") {
    return false;
  }

  if (!validateChecksum(tenDigitFormat)) {
    return false;
  }
  var twelveDigitPersonalNumber = "" + century + tenDigitFormat;

  // Do a last validation on the birth date.
  var year = parseInt(twelveDigitPersonalNumber.substring(0, 4));
  var month = parseInt(twelveDigitPersonalNumber.substring(4, 6)) - 1; // JS months are zero-indexed
  var day = parseInt(twelveDigitPersonalNumber.substring(6, 8));
  var birthDate = new Date(year, month, day, 12);
  // Date wrapped around (e.g february 31st turned into march 3rd)
  if (birthDate.getFullYear() !== year || birthDate.getMonth() !== month || birthDate.getDate() !== day) {
    return false;
  }
  var personAge = new Date().getFullYear() - birthDate.getFullYear();
  // Sanity check: A person cannot be born tomorrow or 200 years ago.
  if (birthDate > new Date() || personAge > 200) {
    return false;
  }

  return true;
};
