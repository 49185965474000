var WizardViewModel = function (options) {

    'use strict';

    var self = this;
    self.options = options;

    // Language settings
    self.supportedLanguages = ko.observableArray(options.supportedLanguages);
    self.currentLanguage = ko.observable(options.currentLanguage);

    // Wizard steps
    self.currentStep = ko.observable();
    self.steps = [];
    self.stepIndex = 0;

    // Allowed transitions
    self.canCancel = ko.observable(true);
    self.canContinue = ko.observable(false);
    self.canFinish = ko.observable(false);
    self.isInfo = ko.observable(options.info);

    self.init = function(){
        // And should we show the info screen?
        if(self.options.info){
            self.steps.push(self.infoStep);
        }
        else {

            self.adaptToPlatform();

            // Skip this block if we're just returning to collect
            if (!self.options.returnedToCollect) {
                // Now, should the user be asked which is the preferred device?
                if (self.options.askForDevice && !self.assumeRemoteDevice) {
                    self.steps.push(self.deviceQuestionStep);
                }

                // Then, if we're asking for device and and there's no prefilled subject
                // or if another device is selected and it is assumed to be remote, and not use qr code, then ask for id number
                // Requires the RPVersionOverride to be set in the SbidConfigBean.
                if ((self.options.askForDevice || self.options.assumeRemoteDevice)
                    && !self.options.idNumber && self.options.identificationMode === 'PERSONAL_NUMBER' && self.options.rpVersionOverrideSet) {
                    self.steps.push(self.idNumberStep);
                }
                self.steps.push(self.startStep);
            }

            self.steps.push(self.collectStep);
            self.steps.push(self.finishStep);
        }

        // The steps are pushed onto the stack, now let's pop and get started
        self.steps[0]();
        return self;
    };

    self.adaptToPlatform = function () {
        // iOS needs some special care wrt app launching
        if(self.options.platformDetector.isIos()) {

            // App launching won't work in an iframe on iOS.
            // Using postMessage is a workaround used by e.g. Signflow.
            var appLaunchingIsUnsupported = self.options.platformDetector.isInIframe() && !self.options.usePostMessage;

            // Assume remote device if app launching is unsupported or if the method is configured to assume it's a remote device.
            // The use case for configuring a method this way would be if it's used in a variety of contexts,
            // some which may not support app launching (iframe, web views without bankid scheme handling, etc)
            if (appLaunchingIsUnsupported || self.options.assumeRemoteDeviceOnIos) {
                console.log("Assuming remote device (iOS)");
                self.options.askForDevice = false;
                self.options.assumeRemoteDevice = true;
                self.options.autoLaunch = false;
                self.options.useAnotherDevice = true;
            }
        }
    };

    self.afterRender = function () {

        if (self.currentStep().afterBinding) {
            self.currentStep().afterBinding();
        }

        document.querySelector('#method').classList.remove('hidden');

    };

    self.setLanguage = function (lang) {
        console.log('Setting language to ' + lang);

        self.currentLanguage(lang);
        document.webL10n.setLanguage(lang);
        self.notifyParentIfSameDomain('language=' + lang);

        if(!signicat.returnedToCollect) {
            // If we're not in flight, tell the server about language choice
            self.options.xhr.get(self.options.serviceUrl + 'l10n?setLanguage=' + lang);
        }
    };

    self.currentStep.subscribe(function (step) {
        if (step.init) {
            step.init();
        }
    });

    self.gotoNext = function () {
        if (self.canContinue()) {
            self.currentStep().submit(function () {
                self.stepIndex++;
                self.nextStep = self.steps[self.stepIndex];
                self.nextStep();
            });
        }
        else {
            if(self.currentStep().validation){
                self.currentStep().validation();
            }
        }
    };

    self.cancel = function () {
        self.canContinue(true);
        if(self.currentStep() instanceof FinishViewModel){
            // If we're already trying to finish,
            // then this is an unrecoverable error
            self.navigateTo(self.options.target);
        }
        else{
            self.options.transAuth.navigateToInternal(self.options.serviceUrl + 'cancel');
        }
    };

    self.dismiss = function () {
        window.parent.postMessage('infoRead', window.location.origin);
    };

    self.navigateTo = function(url){
        self.options.transAuth.navigateToInternal(url);
    };

    self.notifyParentIfSameDomain = function(data){
        if (window.parent) {
            window.parent.postMessage(data, window.location.origin);
        }
    };

    self.onError = function(err){
        console.error(err);
    };

    self.onLogDataSent = function(){
        console.info("Log data transferred");
    };

    self.deviceQuestionStep = function () {
        self.currentStep(new DeviceQuestionViewModel(self, self.options));
    };

    self.idNumberStep = function () {
        self.currentStep(new IdentityNumberViewModel(self, self.options));
    };

    self.startStep = function () {
        self.currentStep(new StartViewModel(self, self.options));
    };

    self.collectStep = function () {
        self.currentStep(new CollectViewModel(self, self.options));
    };

    self.finishStep = function(){
        self.currentStep(new FinishViewModel(self, self.options));
    };

    self.infoStep = function(){
        self.currentStep(new InfoViewModel(self, self.options));
    };

};

var DeviceQuestionViewModel = function (manager, options) {

    'use strict';

    var self = this;
    self.options = options;
    self.manager = manager;

    self.template = 'device-question-template';

    self.deviceQuestionHeadingL10n = ko.observable(self.options.sign ? 'sbid2014-deviceQuestionHeading-sign' : 'sbid2014-deviceQuestionHeading');
    self.logInUsingL10n = ko.observable(self.options.sign ? 'sbid2014-logInUsing-sign' : 'sbid2014-logInUsing');
    var isMobile = self.options.platformDetector.isMobile();
    self.localBankIdL10n = ko.observable(isMobile ? 'sbid2014-localBankId-mobile' : 'sbid2014-localBankId');
    self.mobileBankIdL10n = ko.observable(isMobile ? 'sbid2014-mobileBankId-mobile' : 'sbid2014-mobileBankId');

    self.init = function () {
        self.manager.canContinue(true);
        self.manager.canFinish(true);
    };

    self.chooseThisDevice = function(){
        console.log("Chose to start BankID on the current device");
        self.options.useAnotherDevice = false;
        self.options.sbid.log({ event: "choseDevice", data: "Local"}, self.manager.onLogDataSent, self.manager.onError);
        self.manager.gotoNext();
        return;
    };

    self.chooseAnotherDevice = function(){
        console.log("Chose to start BankID on another device");
        self.options.useAnotherDevice = true;
        self.options.sbid.log({ event: "choseDevice", data: "Remote"}, self.manager.onLogDataSent, self.manager.onError);
        self.manager.gotoNext();
        return;
    };

    self.submit = function (onSuccess) {
        onSuccess();
    };

    self.onError = function (e) {
        self.manager.onError(e);
    };

};

var IdentityNumberViewModel = function (manager, options) {

    'use strict';

    var self = this;

    self.options = options;
    self.manager = manager;
    self.idNumber = ko.observable(options.idNumber);
    self.idNumberHeadingL10n = ko.observable(self.options.sign ? 'sbid2014-idNumberHeading-sign' : 'sbid2014-idNumberHeading');
    self.showReminder = ko.observable(false);
    self.hasIdNumberFocus = ko.observable(false);

    self.template = 'id-number-template';

    self.init = function () {
        if(!self.options.useAnotherDevice){
            console.log('Same-device process, so id number input is not required');
            self.manager.gotoNext();
            return;
        }
        self.manager.canContinue(!self.options.useAnotherDevice || signicat.verifyIdNumber(self.idNumber()));
        self.manager.canFinish(false);
        self.hasIdNumberFocus(true);
    };

    self.idNumber.subscribe(function (data) {
        self.options.idNumber = data;
    });

    self.isValid = ko.pureComputed(function() {
        var isValid = signicat.verifyIdNumber(self.idNumber());
        self.manager.canContinue(!self.options.useAnotherDevice || isValid);
        self.showReminder(self.showReminder() && !isValid);  // Hide reminder when number is valid again
        return isValid;
    }, this);

    self.validation = function(){
        var isValid = self.idNumber() && signicat.verifyIdNumber(self.idNumber());
        self.showReminder(!isValid);
    };

    self.submit = function (onSuccess) {
        self.options.sbid.log({ event: "enteredIdNumber", data: self.idNumber()}, self.manager.onLogDataSent, self.manager.onError);
        onSuccess();
    };

    self.onError = function (e) {
        self.manager.onError(e);
    };

};

var StartViewModel = function(manager, options){

    'use strict';

    var self = this;

    self.options = options;
    self.manager = manager;
    self.inProgress = ko.observable(false);
    self.hideSpinner = ko.observable(!self.options.showSpinner);

    self.launchUrl = ko.observable();
    self.hideLaunchButton = ko.observable(true);
    self.manualLaunchAttempted = ko.observable(false);

    self.useNinFallback = self.options.rpVersionOverrideSet && self.options.identificationMode === 'PERSONAL_NUMBER';

    self.qrCode = ko.observable();
    self.qrCodeVisible = ko.observable(false);
    self.useQrCode = self.options.useAnotherDevice && !self.useNinFallback;
    self.qrRefreshMax = 10;
    self.qrRefreshCount = 0;

    self.startHeadingL10n = ko.observable(self.options.sign ? 'sbid2014-startHeading-sign' : 'sbid2014-startHeading');
    self.startSuccess = true;
    self.manualLaunchTimeoutHandle;
    self.inProcessMessageL10n = ko.observable('sbid2014-pleaseWait');

    self.template = 'start-template';

    self.init = function () {
        self.manager.canContinue(false);
        self.manager.canFinish(true);
    };

    self.afterBinding = function(){
        self.start();
    };

    self.start = function () {
        if(!self.inProgress()) {
            self.inProgress(true);
            self.setInProcessMessage("PLEASE_WAIT");
            self.options.sbid.start({subject: self.options.idNumber, useAnotherDevice: self.options.useAnotherDevice}, self.started, self.showError);
        }
    };

    self.started = function (resp) {
        console.log('started, resp=' + resp);
        var response = options.jsonParse(resp);
        if(response.error){
            self.showError(response);
            return;
        }

        self.inProgress(false);
        self.hideSpinner(true);
        if (self.useQrCode) {
            self.showQrCode(response);
        } else {
            self.launchClient(response);
        }
        self.collect();
    };

    self.collect = function(){
        console.log('Collecting result for orderRef ' + self.options.orderRequest.orderRef);
        self.options.sbid.collect(self.options.orderRequest, self.handleCollectResponse, self.showError);
    };

    self.handleCollectResponse = function(resp){
        var response = options.jsonParse(resp);
        console.log(response);

        if(response.error){
            self.showError(response);
            return;
        }

        if(response.progressStatus && response.progressStatus !== 'OUTSTANDING_TRANSACTION' && response.progressStatus !== 'NO_CLIENT'){
            self.manager.canContinue(true);
            clearTimeout(self.manualLaunchTimeoutHandle);
            self.manager.gotoNext();
            return;
        }

        if (self.useQrCode && response.qrData) {
            self.qrCode(response.qrData);
        }

        self.manualLaunchTimeoutHandle = setTimeout(self.collect, self.options.collectInterval);
    };

    self.setInProcessMessage = function(msgCode) {
        var l10nMsgCode = self.options.msgKeys.get(msgCode);
        console.log(msgCode + " -> " + l10nMsgCode);
        self.inProcessMessageL10n(l10nMsgCode);
    };

    self.showError = function(resp){
        if (resp.error.code === 'START_FAILED' && self.useQrCode) {
            self.qrRefreshCount++;
            if (self.qrRefreshCount <= self.qrRefreshMax) {
                console.log('QR code expired, refreshing... (' + self.qrRefreshCount + '/' + self.qrRefreshMax + ')');
                self.start();
                return;
            }
            console.error('QR code expired, refresh limit has been reached');
            // Error code means one thing when personal number is used, and another whe QR code is used. So two different error messages.
            resp.error.code = resp.error.code + '_QR';
        }
        console.error(resp.error);
        // The init call succeeded, but the process didn't
        self.startSuccess = true;

        // Stop and hide the spinner
        self.inProgress(false);
        self.hideSpinner(true);

        // If the manual start buttons haven't appeared yet, cancel it
        clearTimeout(self.manualLaunchTimeoutHandle);

        // If the manual start button did appear, hide it
        self.hideLaunchButton(true);

        // Set the error message
        self.setInProcessMessage(resp.error.code);

        self.qrCodeVisible(false);

        // Allow the user to cancel
        self.manager.canCancel(true);
    };

    self.showQrCode = function(resp) {
        if (resp.autoStartToken) {
            self.qrCode(resp.qrData);
            self.setInProcessMessage('SCAN_QR_CODE');
            self.qrCodeVisible(true);
        }
    };

    self.showLauncher = function(launchUri){
        console.log('showLauncher, launchUri=' + launchUri);

        // Don't show the launch button if another device is chosen
        if(self.options.useAnotherDevice){
            self.hideSpinner(false);
            self.setInProcessMessage('MANUAL_LAUNCH_ON_ANOTHER_DEVICE');
            return;
        }

        // Add some context help
        self.setInProcessMessage('MANUAL_LAUNCH');

        // Set the launch URI and show the launch button
        self.launchUrl(launchUri);
        self.hideLaunchButton(!self.startSuccess);

        self.options.sbid.log({ event: "promptedToLaunch"}, self.manager.onLogDataSent, self.manager.onError);
    };

    self.launchManually = function(model, event){
        self.manualLaunchAttempted(true);
        self.hideSpinner(false);
        self.setInProcessMessage("MANUAL_LAUNCH_WAITING");
        
        if (self.options.usePostMessage) {
            self.sendUrlToParent(event.target.href);
            return;
        }

        return true;
    };

    self.addInvisibleIframe = function(launchUri){
        var launcher = document.querySelector('.method .launch-frame');
        var iframe = document.createElement('iframe');
        iframe.height = 0;
        iframe.width = 0;
        iframe.style.border = 0;
        iframe.src = launchUri;
        launcher.appendChild(iframe);
    };

    self.launchClient = function (resp){

        self.options.orderRequest.orderRef = resp.orderRef;

        // If the options contains a ticket, push it to the URL before creating the redirect
        if(self.options.ticket){
            // It's going to be a collect call URL with the ticket appended
            var returnTo =
                self.options.serviceUrl.substring(self.options.serviceUrl.indexOf('/std/')) +
                'collect?' + 'signicat.transaction_ticket=' + self.options.ticket;

            if(self.options.server){
                returnTo += '&signicat.server=' + self.options.server;
            }

            history.pushState(null, null, returnTo);
        }

        var autoStartTokenParam = '';
        if(resp.autoStartToken){
            autoStartTokenParam = 'autostarttoken=' + resp.autoStartToken + '&';
        }
        var redirectParam = 'redirect=' + self.options.platformDetector.getRedirectForPlatform(self.options.sbidRedirectUrl);
        var defaultUrl = ((self.options.platformDetector.isIos() && self.options.modernUI) ? 'https://app.bankid.com/?' : 'bankid:///?')
            + autoStartTokenParam + redirectParam;

        if (self.options.autoLaunch && !self.options.useAnotherDevice) {
            self.setInProcessMessage('TRY_AUTO_LAUNCH');
            if (self.options.useIntentForChromeOnAndroid && self.options.platformDetector.isChromeOnAndroid()) {
                var intentUrl = 'intent:///?' + autoStartTokenParam + 'redirect=null#Intent;scheme=bankid;package=com.bankid.bus;end';
                self.openLaunchUrl(intentUrl, self.options.usePostMessage, false);
            } else {
                self.openLaunchUrl(defaultUrl, self.options.usePostMessage, true);
            }

            if (self.options.platformDetector.isIos()) {
                // Auto-launch most likely don't work on iOS, so show launch button immediately
                self.showLauncher(defaultUrl);
            } else {
                self.manualLaunchTimeoutHandle = setTimeout(function() {
                    self.showLauncher(defaultUrl);
                }, 5000);
            }
        } else {
            self.showLauncher(defaultUrl);
        }
    };

    self.openLaunchUrl = function (url, usePostMessage, useInvisibleIframe) {
        console.log('Try to auto-launch bankid with url: ' + url);
        if (usePostMessage) {
            self.sendUrlToParent(url);
        } else if (useInvisibleIframe) {
            self.addInvisibleIframe(url);
        } else {
            document.location = url;
        }
    };

    self.sendUrlToParent = function (url) {
        if (window.parent) {
            window.parent.postMessage(JSON.stringify({externalUrl: url}), window.location.origin);
        }
    };

    self.submit = function (onSuccess) {
        onSuccess();
    };

    self.onError = function (e) {
        self.manager.onError(e);
    };

};

var CollectViewModel = function (manager, options) {

    'use strict';

    var self = this;

    self.options = options;
    self.manager = manager;
    self.inProgress = ko.observable(false);
    self.hideSpinner = ko.observable(!self.options.showSpinner);
    self.collectHeadingL10n = ko.observable(self.options.sign ? 'sbid2014-collectHeading-sign' : 'sbid2014-collectHeading');
    self.inProcessMessageL10n = ko.observable('sbid2014-pleaseWait');
    self.startedStatusCount = 0;

    self.template = 'collect-template';

    self.init = function () {
        self.manager.canContinue(false);
        self.manager.canFinish(true);
    };

    self.afterBinding = function(){
        self.collect();
    };

    self.collect = function(){
        self.options.sbid.collect(self.options.orderRequest, self.handleCollectResponse, self.showError);
    };

    self.handleCollectResponse = function(resp){
        var response = options.jsonParse(resp);

        if(response.error){
            self.showError(response);
            return;
        }

        var displayStatus = response.progressStatus;

        // STARTED status is returned from collect if BISP/BISApp
        // was successfully started, but there is no BankID
        // matching the id number or requirements on it (yet).
        //
        // It could take a short while before the SBID app has loaded
        // the BankIDs, so we allow STARTED status in a few collect
        // calls before treating it as an error.
        if(response.progressStatus === 'STARTED'){
            self.startedStatusCount++;

            // If no BankID is found after some collect calls, we treat it as an error
            if (self.startedStatusCount >= 5) {
                response.error = { code: response.progressStatus };
                self.showError(response);
                return;
            }

            // Otherwise just display a waiting message and continue doing collect calls
            displayStatus = 'SEARCHING';
        }

        if(response.progressStatus === 'COMPLETE' || response.progressStatus === 'CANCEL'){
            self.manager.canContinue(true);
            self.manager.gotoNext();
            return;
        }

        self.setInProcessMessage(displayStatus);

        setTimeout(self.collect, self.options.collectInterval);
    };

    self.setInProcessMessage = function(msgCode) {
        var l10nMsgCode = self.options.msgKeys.get(msgCode);
        console.log(msgCode + " -> " + l10nMsgCode);
        self.inProcessMessageL10n(l10nMsgCode);
    };

    self.showError = function(resp){
        console.error(resp);
        self.inProgress(false);
        self.hideSpinner(true);
        self.setInProcessMessage(resp.error.code);
    };

    self.submit = function (onSuccess) {
        onSuccess();
    };

    self.onError = function (e) {
        self.manager.onError(e);
    };

};

var FinishViewModel = function (manager, options) {

    'use strict';

    var self = this;

    self.options = options;
    self.manager = manager;
    self.hideSpinner = ko.observable(!self.options.showSpinner);
    self.finishHeadingL10n = ko.observable(self.options.sign ? 'sbid2014-finishHeading-sign' : 'sbid2014-finishHeading');
    self.inProcessMessageL10n = ko.observable('sbid2014-pleaseWait');

    self.template = 'finish-template';

    self.init = function () {
        self.manager.canContinue(false);
        self.manager.canFinish(true);
    };

    self.afterBinding = function(){
        self.redirectToComplete();
    };

    self.redirectToComplete = function () {
        self.options.transAuth.navigateToInternal(self.options.serviceUrl + 'complete');
    };

    self.submit = function (onSuccess) {
        onSuccess();
    };

};

var InfoViewModel = function (manager, options) {

    'use strict';

    var self = this;

    self.options = options;
    self.manager = manager;

    self.template = 'info-template';

    self.init = function () {
        self.manager.canCancel(false);
        self.manager.canFinish(true);
    };

    self.afterBinding = function(){
    };

    self.submit = function (onSuccess) {
        onSuccess();
    };

    self.onError = function (e) {
        self.manager.onError(e);
    };

};

signicat.addEvent(window, 'load', function(){
    signicat.manager = new WizardViewModel(signicat).init();
    ko.applyBindings(signicat.manager, document.querySelector('.method'));
    signicat.manager.setLanguage(signicat.currentLanguage);
});
