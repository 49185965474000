var SbidProxy = (function(){

    'use strict';

    var $serviceUrl,
        $xhr,
        orderPath = 'order',
        logPath = 'log',
        collectPath = 'collect',
        completePath = 'complete';

    var SbidProxy = function(serviceUrl, xhr){
        $serviceUrl = serviceUrl;
        $xhr = xhr;
    };

    SbidProxy.prototype = {

        constructor: SbidProxy,

        start: function(subject, success, error) {
            $xhr.post($serviceUrl + orderPath, JSON.stringify(subject), success, error);
        },

        log: function(logData, success, error) {
            $xhr.post($serviceUrl + logPath, JSON.stringify(logData), success, error);
        },

        collect: function(orderRef, success, error) {
            $xhr.post($serviceUrl + collectPath, JSON.stringify(orderRef), success, error);
        }

    };

    return SbidProxy;

}());