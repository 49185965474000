ko.bindingHandlers.qrcode = {
  update: function(element, valueAccessor, allBindings) {
    var code = ko.unwrap(valueAccessor());
    var qrCode = ko.utils.domData.get(element, 'qrcode');

    if (code !== undefined) {
      if (qrCode === undefined) {
        var qrOptions = {
          text: code,
          visible: true,
          colorDark : allBindings.get('color'),
          correctLevel: QRCode.CorrectLevel.L,
          width: allBindings.get('width'),
          height: allBindings.get('height'),
        };
        var qrCode = new QRCode(element, qrOptions);
        qrCode._el.title = "";
        ko.utils.domData.set(element, 'qrcode', qrCode);
      } else {
        qrCode.makeCode(code);
        qrCode._el.title = "";
      }
    }
  },
};
