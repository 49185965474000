/**
 * l10n knockout binding
 *
 * Initialize with:
 *     window.l10nko.init();
 * And use like this:
 *     <span data-bind="l10n: 'id-in-defaulttexts'">Default text</span>
 * Or with a knockout observable:
 *      self.l10nId = ko.observable('module.button.text');
 *      <span data-bind="l10n: l10nId">Default</span>
 */
(function () {
    var l10n, ko;
    if (typeof require !== 'undefined') {
        l10n = require('l10n');
    } else {
        l10n = document.webL10n;
    }

    var koBindingHandler = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            koBindingHandler.update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
        },

        update: function (element, valueAccessor) {
            var value = ko.toJS(valueAccessor());
            if (typeof value === 'string') {
                element.setAttribute('data-l10n-id', value);
                l10n.translate(element);
            }
        }
    };

    var l10nko = {
        init: function (knockout) {
            ko = knockout || window.ko;
            ko.bindingHandlers['l10n'] = koBindingHandler;
        },

        // Get the underlying i10n object
        l10n: l10n
    };

    if (typeof module !== 'undefined') {
        module.exports = l10nko;
    } else {
        window.l10nko = l10nko;
    }
})();
