var Xhr = (function(){

    'use strict';

    var $transAuth;

    var Xhr = function (transAuth) {
        $transAuth = transAuth;
    };

    Xhr.prototype = {

        constructor: Xhr,

        get: function (url, success, error) {
            console.log("Calling " + url);
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            $transAuth.prepareXhr(request);
            request.onerror = function(e) {
                console.error("Request to " + url + " failed.");
                var err = {
                    error: {
                        code: "TECHNICAL_ERROR",
                        message: e.target.status
                    }
                };
                if(error){
                    error(err);
                }
            };
            request.onreadystatechange = function(){
                if(this.readyState == 4) {
                    if (this.status >= 200 && this.status < 400 && success){
                        success(this.responseText);
                    } else {
                        if(error){
                            error({
                                error: {
                                    code: "TECHNICAL_ERROR",
                                    message: this.status + ' ' + this.responseText
                                }
                            });
                        }
                    }
                }
            };
            request.send();
        },

        post: function(url, json, success, error){
            console.log("Calling " + url);
            var request = new XMLHttpRequest();
            request.open('POST', url, true);
            $transAuth.prepareXhr(request);
            request.setRequestHeader('Content-Type', 'application/json');
            request.onerror = function(e) {
                console.error("Request to " + url + " failed.");
                var err = {
                    error: {
                        code: "TECHNICAL_ERROR",
                        message: e.target.status
                    }
                };
                error(err);
            };
            request.onreadystatechange = function(){
                if(this.readyState == 4) {
                    if (this.status >= 200 && this.status < 400){
                        success(this.responseText);
                    } else {
                        error({
                            error: {
                                code: "TECHNICAL_ERROR",
                                message: this.status + ' ' + this.responseText
                            }
                        });
                    }
                }
            };
            request.send(json);
        }
    };

    return Xhr;

}());